import { safeParseDate } from '@dte/otw/utils/core/src/dates/safeParseDate';
import { filterEmpty } from '@dte/otw/utils/core/src/lists/filterEmpty';
import { mergeArrays } from '@dte/otw/utils/core/src/lists/mergeArrays';
import { WebEnvironment } from './WebEnvironment';

const webEnv = process.env.NEXT_PUBLIC_WEB_ENV as WebEnvironment;
export const isProd = webEnv === WebEnvironment.Prod;

if (!isProd) {
	console.log('build environment: ', process.env.NODE_ENV);
	console.log('web environment: ', webEnv);
}

export function pageTitle(parts?: string[]): string {
	let titleParts = ['DTE Outage'];

	titleParts = mergeArrays(titleParts, parts);
	titleParts = filterEmpty(titleParts);

	return titleParts.join(' - ');
}

export const buildDate = safeParseDate(process.env.NEXT_PUBLIC_BUILD_DATE);
console.log('build date: ', process.env.NEXT_PUBLIC_BUILD_DATE);
