import { CompareOrder } from '../../objects/compare/CompareOrder';
import { compareGreater } from '../../objects/compare/compareGreater';
import { safeParseDate } from '../safeParseDate';

export function compareLatest(
	a: string | number | Date,
	b: string | number | Date,
	// For sorting, put undefined at the start of the list by default
	handleUndefined: CompareOrder.Before | CompareOrder.After = CompareOrder.Before,
): CompareOrder {
	const timeA = safeParseDate(a)?.getTime();
	const timeB = safeParseDate(b)?.getTime();

	return compareGreater(timeA, timeB, handleUndefined);
}
