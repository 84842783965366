'use client';

import { isTruthy } from '@dte/otw/utils/core/src/strings/isTruthy';
import {
	AppInsightsContext,
	ReactPlugin,
	useAppInsightsContext,
	withAITracking,
} from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, type IConfig, type IConfiguration } from '@microsoft/applicationinsights-web';
import { type PropsWithChildren, useCallback } from 'react';
import { isProd } from '../../common/util/environmentConfig';
import { filterDependencyTracking } from './filterDependencyTracking';

const connectionString = process.env.NEXT_PUBLIC_APP_INSIGHTS_CONNECTION_STRING;

let disableTelemetry = false;
// Telemetry should always be eanbled in prod
if (!isProd) {
	disableTelemetry = isTruthy(process.env.NEXT_PUBLIC_APP_INSIGHTS_DISABLE_TELEMETRY);
}
if (!connectionString) {
	console.warn('Missing App Insights connection string');
	disableTelemetry = true;
}

if (disableTelemetry) {
	console.log('App Insights Telemetry is disabled');
} else if (!isProd) {
	console.log('App Insights Telemetry is enabled');
}

const config: IConfiguration & IConfig = {
	connectionString: connectionString,

	enableAutoRouteTracking: true,
	enableAjaxPerfTracking: true,
	enableAjaxErrorStatusText: true,
	enableCorsCorrelation: true,
	correlationHeaderDomains: ['*.dteenergy.com'],

	// Monitor all AJAX calls
	maxAjaxCallsPerView: -1,

	// Whether or not to disable telemetry
	disableTelemetry: disableTelemetry,
};

const appInsights = new ApplicationInsights({
	config: config,
});
appInsights.addTelemetryInitializer(filterDependencyTracking);

const reactPlugin = new ReactPlugin();
appInsights.addPlugin(reactPlugin);

appInsights.loadAppInsights();

// Wrapper in case we decide to allow other methods for accessing app insights
export function useAppInsights() {
	// TODO: queue up any logs that come in before the plugin is ready
	return useAppInsightsContext();
}

// React plugin doesn't expose the interface needed for this, so we need to access it directly
// TODO: update this if that changes: https://github.com/microsoft/applicationinsights-react-js/issues/19
export function useSetAuthenticatedUserContext() {
	return useCallback((authenticatedUserId: string, accountId?: string, storeInCookie: boolean = true) => {
		if (!appInsights) {
			return;
		}
		appInsights.setAuthenticatedUserContext(authenticatedUserId, accountId, storeInCookie);
	}, []);
}

// To instrument various React components usage tracking, apply the `withAITracking` higher-order
// component function.
const _AppInsightsWrapper = (innerProps: PropsWithChildren) => {
	return <AppInsightsContext value={reactPlugin}>{innerProps.children}</AppInsightsContext>;
};
export const AppInsightsWrapper = withAITracking(reactPlugin, _AppInsightsWrapper);
